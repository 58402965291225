import request from '@/utils/request'

export function getObstacleDiseaseById(data) {
  return request({
    url: `/api/ObstacleDiseases/GetObstacleDiseaseById/${data}`,
    method: 'get',
  })
}

export function getObstacleDiseases() {
  return request({
    url: '/api/ObstacleDiseases/GetObstacleDiseases',
    method: 'get',
  })
}

export function addObstacleDisease(data) {
  return request({
    url: '/api/ObstacleDiseases/AddObstacleDisease',
    method: 'post',
    data,
  })
}

export function updateObstacleDisease(data) {
  return request({
    url: '/api/ObstacleDiseases/UpdateObstacleDisease',
    method: 'put',
    data,
  })
}
export function deleteObstacleDisease(data) {
  return request({
    url: `/api/ObstacleDiseases/DeleteObstacleDisease?frObstacleDisaseId=${data}`,
    method: 'delete',
  })
}
